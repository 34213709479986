import { GetServerSideProps } from 'next';

import AuthoredPageRoutes from '@/components/authored-page-routes/authored-page-routes';
import { PreRenderProps } from '@/pages/_app';
import { getViewDefinition } from '@/ssr/view-definition-page-query';
import { fetchDataAndHandleErrors } from '@/utils/ssr-utils';

export const getServerSideProps: GetServerSideProps<PreRenderProps> = async (context) => {
  const data = await fetchDataAndHandleErrors(context, getViewDefinition, undefined);
  return { props: data };
};

const Home = (props: any) => <AuthoredPageRoutes {...props} />;

export default Home;
