import { TUseStructuredDataForSeo, TValueUnknown } from './page-seo-meta.interface';

/**
 * Function to recursively parse the data and replace the keys with the values.
 * @param key The key to use for the structured data
 * @param valueFn The value function to use to replace the key data
 * @param data The input data
 * @returns structured data with the key replaced
 */
export const recursivelyUpdateField = (
  key: string,
  valueFn: (value: TValueUnknown) => TValueUnknown,
  data: TValueUnknown
): TUseStructuredDataForSeo => {
  if (data[key]) {
    data[key] = valueFn(data[key]);
  }
  Object.keys(data).forEach((k) => {
    if (typeof data[k] === 'object') {
      if (Array.isArray(data[k])) {
        data[k].forEach((item: TValueUnknown) => {
          recursivelyUpdateField(key, valueFn, item);
        });
      } else {
        recursivelyUpdateField(key, valueFn, data[k]);
      }
    }
  });
  return data;
};

/** Function to check if we are in a browser environment */
export const isBrowser = () => typeof window !== 'undefined';
