import { ViewMetaData } from '@/components/authored-page-routes/authored-page-routes.interface';

enum EPageTypes {
  CATEGORY = 'category',
  COLLECTION = 'collection',
  BRAND = 'brand',
}

interface IJsonLDFunctionSignature {
  (pageType: `${EPageTypes}` | string, viewDefinitionResults: any, viewMetaData?: ViewMetaData): string;
}

const baseLDJson = {
  '@context': 'https://schema.org/',
  '@type': 'WebPage',
};

const baseProductTypeJson = {
  '@context': 'https://schema.org/',
  '@type': 'Product',
};

const baseItemOffersTypeJson = {
  '@type': 'Offer',
  priceCurrency: 'CAD',
  itemCondition: 'https://schema.org/NewCondition',
};

const baseMainEntityJson = {
  '@context': 'https://schema.org/',
  '@type': 'ProductCollection',
};

const baseOffersTypeJson = {
  '@type': 'Offer',
};

export const jsonLdPageTypeBuilder: IJsonLDFunctionSignature = (pageType, viewDefinitionResults, viewMetaData) => {
  const DEFAULT_EMPTY_JSON = '{}';

  switch (pageType) {
    case EPageTypes.CATEGORY:
    case EPageTypes.BRAND:
    case EPageTypes.COLLECTION: {
      const productGrids =
        viewDefinitionResults?.viewDefinition?.layout?.sections?.mainContentCollection?.components?.filter(
          ({ componentId }: any) => componentId === 'productGridComponent'
        );

      if (!productGrids || !productGrids.length) break;

      const mergedProductsList = productGrids.reduce((acc: [], grid: any) => [...acc, ...grid.data.productTiles], []);

      if (!mergedProductsList.length) break;

      const itemsOffered = mergedProductsList.map(
        ({ brand, title, productImage, articleNumber, pricing, link }: any) => ({
          ...baseProductTypeJson,
          name: `${brand} ${title}`,
          url: link,
          image: productImage?.[0]?.imageUrl,
          sku: `BB_${articleNumber}`,
          description: title,
          brand,
          offers: {
            ...baseItemOffersTypeJson,
            price: pricing?.price
              ? pricing?.price
              : parseInt(pricing?.displayPrice?.substring(1, pricing?.displayPrice?.indexOf('-') - 1)),
            url: link,
          },
        })
      );
      const seoPLPData = {
        ...baseLDJson,
        name: viewMetaData?.headline,
        description: viewMetaData?.description,
        url: viewMetaData?.canonicalUrlSlug,
        mainEntity: {
          ...baseMainEntityJson,
          offers: {
            ...baseOffersTypeJson,
            itemOffered: itemsOffered,
          },
        },
      };
      return JSON.stringify(seoPLPData);
    }
    default:
      break;
  }

  return DEFAULT_EMPTY_JSON;
};
